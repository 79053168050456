import React from 'react';
import ReactDOM from 'react-dom/client';  // تغییر این خط
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeContextProvider from './contexts/ThemeContext';  // فقط یکی از این خطوط را نگه دارید
import './assets/fonts/IRANSansWeb/IRANSansWeb.woff2'
import './assets/fonts/Estedad/Estedad-Regular.woff2'

import { ThemeProvider } from '@mui/material/styles'; // وارد کردن ThemeProvider

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"



const root = ReactDOM.createRoot(document.getElementById('root')); // ایجاد روت جدید
root.render(
   <ThemeContextProvider>
      <App />
   </ThemeContextProvider>
);

reportWebVitals();
