import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation(); // گرفتن اطلاعات موقعیت فعلی

  useEffect(() => {
    // هر بار که مسیر تغییر کند، صفحه به بالا اسکرول می‌شود
    window.scrollTo(0, 0);
  }, [location]); // وابستگی به location که هر بار تغییر کند این افکت اجرا می‌شود

  return null;
}

export default ScrollToTop;
